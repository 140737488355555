<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
  >
    <v-row>
      <h5 class="text-center"><strong>{{ $t('message.two_factor_authentication_reset_title') }}</strong></h5>

      <br>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import Vue from 'vue'
import config from '../../config'
import router from '../../router'

export default {
  name: 'ResetQrCode',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: []
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.two_factor_authentication_reset_title')
    this.resetQrCode()
  },
  methods: {
    resetQrCode () {
      const token = this.$route.query.token

      const data = {
        token: token
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/reset-qr-code', formData)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data
              this.errors = []

              setTimeout(() => {
                this.$store.dispatch('logout')
              }, 3000)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }
        })
    }
  }
}
</script>
